<template>
  <div class="searchOrder">
    <div class="list-title">Import Orders</div>
    <div class="sample">
      <a-space>
        <div class="top-title">Sample File</div>
        <a-button type="primary" shape="round">
          <a style="text-decoration:none;" :href="orderSampleUrl + '?' + Math.random()"
            :download="'OrderSampleImperial_' + orderVersion + '.xlsx'">
            <icon-download />Download
          </a>
        </a-button>
      </a-space>
    </div>
    <div class="sample-tips">
      1. Only support <span class="mark">IMPERIAL ( inch | lb | oz );</span><br />
      2. Our sample file version is <span class="mark">{{ orderVersion }}.</span> Download new sample file if yours was
      downloaded earlier, Otherwise system won't recognize the format;<br />
      3. Make sure the file in the same format of downloaded file;<br />
      4. Do not change the file extension and format. It has to be a <span class="mark"> xlsx file.</span><br />
      5. File name can only contain <span class="mark">letters, numbers, hyphens and underscores.</span>
    </div>
    <div class="import">
      <a-card :style="{ width: '660px' }" title="Import">
        <a-config-provider :locale="enUS">
          <a-upload draggable :custom-request="customRequest" @before-upload="beforeUpload" tip="Only xlsx file can be Imported,
      and file name can only contain letters, numbers, hyphens and underscores." multiple accept=".xlsx">
          </a-upload>
        </a-config-provider>
      </a-card>
    </div>
    <div class="top-title record">Imported Records</div>
    <!-- 表格 -->
    <a-config-provider :locale="enUS">
      <a-table :columns="listColumns" :data="listData.value" :bordered="false" :pagination="false">
        <template #empty>
          <div v-if="listData.value.length === 0" class="empty-cont">
            <img src="../../../assets/images/empty/listempty.svg" alt="">
            <div>No results found</div>
          </div>
        </template>
        <template #status="{ record }">
          <a-tag color="red" v-if="record.status !== 'Importing' && record.status === 'Failed'">
            <template #icon>
              <icon-close-circle-fill />
            </template>
            {{ record.status }}
          </a-tag>
          <a-tag color="green" v-else-if="record.status !== 'Importing' && record.status === 'Done'">
            <template #icon>
              <icon-check-circle-fill />
            </template>
            {{ record.status }}
          </a-tag>
          <a-tag color="orange" v-else>
            <template #icon>
              <icon-loading />
            </template>
            {{ record.status }}
          </a-tag>
        </template>
        <template #filename="{ record }">
          <a style="text-decoration:none;color:#000" :href="adslink + record.upload_filename_url"
            :download="record.filename">
            <span v-if="record.status == 'Failed'" style=" color: #FC5E5E;">{{
              record.filename
            }}</span>
            <span v-else-if="record.status == 'Importing'" style="color:#86909C;">{{
              record.filename
            }}</span>
            <span v-else style="color:#13B966">{{
              record.filename
            }}</span>
          </a>
        </template>
        <template #error="{ record }">
          <a-button type="text" :disabled="record.error === 0">
            <a style="text-decoration:none;" class="table-text" :href="adslink + record.error_filename_url"
              :download="record.error_filename" v-if="record.error !== 0">
              {{ record.error }}
            </a>
            <span v-else class="table-text">{{ record.error }}</span>
          </a-button>
        </template>
        <template #awaitingpayment="{ record }">
          <a-button type="text" :disabled="record.awaitingpayment === 0" @click="goawaitingpayment(record.key)"
            class="table-text">
            {{ record.awaitingpayment }}</a-button>
        </template>
        <template #awaitingshipment="{ record }">
          <a-button type="text" :disabled="record.awaitingshipment === 0" @click="goawaitingshipment(record.key)"
            class="table-text">
            {{ record.awaitingshipment }}</a-button>
        </template>
        <template #shipped="{ record }">
          <a-button type="text" :disabled="record.shipped === 0" @click="goshipped(record.key)" class="table-text">{{
            record.shipped
          }}
          </a-button>
        </template>
        <template #canceled="{ record }">
          <a-button type="text" :disabled="record.canceled === 0" @click="gocanceled(record.key)" class="table-text">
            {{ record.canceled }}</a-button>
        </template>
        <template #action="{ record }">
          <a-tooltip content="Detail">
            <a-button shape="circle" @click="goDetail(record.key)" v-if="record.status === 'Done'">
              <icon-right />
            </a-button>
          </a-tooltip>
          <a-tooltip :content="record.errCase" v-if="record.status === 'Failed'">
            <a-button shape="circle" status="danger">
              <icon-question-circle />
            </a-button>
          </a-tooltip>
        </template>
      </a-table>
    </a-config-provider>
  </div>
</template>

<script setup>
import { ref, reactive } from 'vue'
import { useRouter, onBeforeRouteLeave } from 'vue-router'
import enUS from '@arco-design/web-vue/es/locale/lang/en-us'
import {
  IconRight, IconDownload, IconQuestionCircle, IconCloseCircleFill, IconCheckCircleFill, IconLoading
} from '@arco-design/web-vue/es/icon'
import { Modal, Notification, Button } from '@arco-design/web-vue'
import local from '@/utils/local.js'
import { getUploadOrderInfo } from '@/api/order.js'
// 使用pinia
import { mainStore } from '@/store/index.js'
const adslink = process.env.VUE_APP_ADSLINK
const store = mainStore()
const customRequest = (option) => {
  const { onProgress, onError, onSuccess, fileItem } = option
  const xhr = new XMLHttpRequest()
  if (xhr.upload) {
    xhr.upload.onprogress = function (event) {
      let percent;
      if (event.total > 0) {
        // 0 ~ 1
        percent = event.loaded / event.total;
      }
      onProgress(percent, event);
    }
  }
  xhr.onload = function onload () {
    let msg = JSON.parse(xhr.response)
    if (msg.code !== 0) {
      Notification.error({
        title: 'Upload error!',
        content: () => {
          return (<div>{msg.message}</div>)
        }
      })
      return onError()
    } else {
      onSuccess(xhr.response)
      let enoughtBalance = true
      let isEnough = setInterval(() => {
        if (store.importData.some(item => item.EnoughBanance === 1)) {
          enoughtBalance = false
        }
        if (enoughtBalance === false) {
          if (aler !== true) {
            clearInterval(isEnough)
            Notification.warning({
              id: 'payImportorder',
              title: 'Insufficient Balance!',
              content: 'The balance is insufficient, getting labels has been stopped, and the order will continue automatically after recharging.',
              duration: 0,
              closable: true,
              footer: <Button type="primary" size="small" shape="round" onClick={closeNotification}>
                Recharge
              </Button>
            })
            aler = true
          }
        }
      }, 3000)
    }
  }
  let aler = false
  const formData = new FormData()
  const token = local.get('token')
  formData.append('file', fileItem.file)
  xhr.open('post', adslink + '/api/merchant/orderImport/v2', true);
  xhr.setRequestHeader("Authorization", 'Bearer ' + token)
  xhr.send(formData);
  return {
    abort () {
      xhr.abort()
    }
  }
}
const closeNotification = () => {
  Notification.remove('payImportorder')
  let routerUrl = router.resolve({ path: '/usercenter/recharge' })
  window.open(routerUrl.href, '_blank')
}
const beforeUpload = (file) => {
  const h = file.name.substring(file.name.lastIndexOf('.') + 1)
  return new Promise((resolve, reject) => {
    if (h === 'xlsx' || h === 'csv') {
      Modal.confirm({
        title: 'Confirm Upload',
        content: `Are you sure to upload ${file.name}`,
        okText: 'Confirm',
        cancelText: 'Cancel',
        onOk: () => resolve(true)
      })
    } else {
      Notification.error({
        title: 'Upload Error',
        content: 'Sorry, only csv/xlsx files are allowed.'
      })
    }
  })
}

const router = useRouter()
const listColumns = [
  {
    title: 'No.',
    dataIndex: 'no',
    slotName: 'no',
    width: 50
  },
  {
    title: 'Status',
    dataIndex: 'status',
    slotName: 'status',
    align: 'center',
    width: 140
  },
  {
    title: 'Filename',
    dataIndex: 'filename',
    ellipsis: true,
    tooltip: true,
    slotName: 'filename'
  },
  {
    title: 'Imported At',
    dataIndex: 'importat',
    width: 180
  },
  {
    title: 'Total',
    dataIndex: 'total'
  },
  {
    title: 'Imported',
    dataIndex: 'imported'
  },
  {
    title: 'Error',
    dataIndex: 'error',
    slotName: 'error'
  },
  {
    title: 'Awaiting Payment',
    dataIndex: 'awaitingpayment',
    slotName: 'awaitingpayment'
  },
  {
    title: 'Awaiting Shipment',
    dataIndex: 'awaitingshipment',
    slotName: 'awaitingshipment'
  },
  {
    title: 'Shipped',
    dataIndex: 'shipped',
    slotName: 'shipped'
  },
  {
    title: 'Canceled',
    dataIndex: 'canceled',
    slotName: 'canceled'
  },
  {
    title: 'Action',
    dataIndex: 'action',
    slotName: 'action'
  }
]
// 表格数据
let listData = reactive({ value: [] })
// 当前页s
const currentpage = ref(1)
// 数据是否都加载完毕
const getList = async () => {
  const msg = await getUploadOrderInfo({
    page: currentpage.value
  })
  if (msg.code === 0) {
    let info = msg.data
    let currentData = []
    for (let i = 0; i < info.length; i++) {
      currentData.push({ key: info[i].upload_id, no: i + 1 + (currentpage.value - 1) * 20, status: info[i].status, importat: info[i].imported_at, filename: info[i].filename, total: info[i].total, imported: info[i].created, error: info[i].error, awaitingpayment: info[i].awaiting_payment, awaitingshipment: info[i].awaiting_shipment, shipped: info[i].shipped, canceled: info[i].canceled, errCase: info[i].error_msg, error_filename_url: info[i].error_filename_url, error_filename: info[i].error_filename, upload_filename_url: info[i].upload_filename_url })
    }
    listData.value = currentData
  } else if (msg.code === 2) {
    // 关闭定时器
    clearInterval(timer.value)
  }
}
// 定时器
const timer = ref(null)
timer.value = setInterval(() => {
  getList()
}, 3000)
onBeforeRouteLeave(() => {
  // 关闭定时器
  clearInterval(timer.value)
})
// 跳转import detail
const goawaitingpayment = (key) => {
  local.set('goimportdetail', 1)
  let routerUrl = router.resolve({ path: '/order/import/importordersdetail', query: { upload: key } })
  window.open(routerUrl.href, '_blank')
}
const goawaitingshipment = (key) => {
  local.set('goimportdetail', 2)
  let routerUrl = router.resolve({ path: '/order/import/importordersdetail', query: { upload: key } })
  window.open(routerUrl.href, '_blank')
}
const goshipped = (key) => {
  local.set('goimportdetail', 4)
  let routerUrl = router.resolve({ path: '/order/import/importordersdetail', query: { upload: key } })
  window.open(routerUrl.href, '_blank')
}
const gocanceled = (key) => {
  local.set('goimportdetail', 5)
  let routerUrl = router.resolve({ path: '/order/import/importordersdetail', query: { upload: key } })
  window.open(routerUrl.href, '_blank')
}
function goDetail (a) {
  let routerUrl = router.resolve({ path: '/order/import/importordersdetail', query: { upload: a } })
  window.open(routerUrl.href, '_blank')
}
const orderVersion = JSON.parse(local.get('configInfo')).order_sample_version
const orderSampleUrl = JSON.parse(local.get('configInfo')).order_sample_url
</script>
<style lang="less" scoped>
.searchOrder {
  padding: 0 24px 24px 24px;

  .list-title {
    font-weight: 500;
    font-size: 20px;
    color: var(--color-text-1);
    margin: 18px 0 18px 0;
  }

  .sample {
    padding-bottom: 8px;
    border-bottom: 1px solid var(--color-border-2);
  }

  .sample-tips {
    margin: 16px 16px 42px 16px;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: var(--color-text-3);

    .mark {
      color: var(--color-text-1);
    }
  }

  .import {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .top-title {
    font-weight: 500;
    font-size: 16px;
    color: var(--color-text-1);
  }

  .record {
    padding: 20px 0;
  }
}

.empty-cont {
  padding: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  div {
    color: rgba(28, 31, 35, 0.8);
    font-weight: 400;
    font-size: 14px;
    padding-top: 30px;
  }
}

.downLoad-icon {
  color: rgb(var(--primary-6));
  cursor: pointer;
}

.table-text {
  color: var(--color-text-1) !important;
}

.table-text.arco-btn-disabled,
.arco-btn-text[type='button'].arco-btn-disabled {
  color: var(--color-text-1);
}
</style>
